import React from 'react';
import { Box, Typography, useMediaQuery, TextField } from '@mui/material';
import { StyledButton, StyledTextField } from '../../components/StyledComponents';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CreateChannel = ({ selectedServer, updateChannels }) => {
    const [createChannelname, setChannelname] = React.useState("")
    const [channelPrivacy, setChannelPrivacy] = React.useState("public")
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 750px)');

    const CreateChannel = () => {
        if(!createChannelname) {
            return;
        }

        const staffRead = channelPrivacy === "staffOnly" ? true : false;
        const staffWrite = channelPrivacy === "public" ? false : true;

        axios.post('https://api.estopia.net/api/channels', {
            serverId: selectedServer._id,
            name: createChannelname,
            staffRead: staffRead,
            staffWrite: staffWrite
        }, {
            withCredentials: true
        })
            .then(response => {
                console.log(response.data);
                if(isMobile){
                    navigate(`/home/server/${selectedServer._id}`);
                } else {
                    navigate(`/home/server/${selectedServer._id}/channel/${response.data._id}`);
                }
                
                updateChannels()
            })
            .catch(error => {
                console.error(error);
        });
    };

    return (
        <Box sx={{ width: '100%', height: '100vh', padding: 2, marginTop: "64px" }}>
            <Typography variant="h4" textAlign="center" marginBottom="32px">Create a new channel:</Typography>
            <StyledTextField
                label="Channel Name"
                variant="outlined"
                fullWidth
                value={createChannelname}
                onChange={(e) => setChannelname(e.target.value)}
            />
            <TextField
                select
                label="Channel Privacy"
                value={channelPrivacy}
                onChange={(e) => setChannelPrivacy(e.target.value)} 
                SelectProps={{
                    native: true
                }}
            >
                <option value="public">Public (Public can text and see messages)</option>
                <option value="staffWrite">Staff Write (Staff can text and members can only read)</option>
                <option value="staffOnly">Staff Channels (Staff can read and see, members cannot see)</option>
            </TextField>
            <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={CreateChannel}
            >
                Create
            </StyledButton>
        </Box>
    );
};

export default CreateChannel;