import React from 'react';
import { Box, Typography } from '@mui/material';
import { StyledButton, StyledTextField } from '../../components/StyledComponents';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CreateServer = ({ createServerName, setCreateServerName }) => {
    const navigate = useNavigate();

    const CreateServer = () => {
        if(!createServerName) {
            return;
        }

        axios.post('https://api.estopia.net/api/servers', {
            name: createServerName,
            description: 'Not Set'
        }, {
            withCredentials: true
        })
            .then(response => {
                console.log(response.data);
                navigate('/home');
            })
            .catch(error => {
                console.error(error);
        });
    };

    return (
        <Box sx={{ width: '100%', height: '100vh', padding: 2, marginTop: "64px" }}>
            <Typography variant="h4" textAlign="center" marginBottom="32px">Create a new server:</Typography>
            <StyledTextField
                label="Server Name"
                variant="outlined"
                fullWidth
                value={createServerName}
                onChange={(e) => setCreateServerName(e.target.value)}
            />
            <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={CreateServer}
            >
                Create
            </StyledButton>
        </Box>
    );
};

export default CreateServer;