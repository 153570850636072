import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../images/Logo.png'
import styled from '@emotion/styled';
import axios from 'axios';
import Cookies from 'js-cookie';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import SettingsIcon from '@mui/icons-material/Settings';

// Styled components
const StyledAppBar = styled(AppBar)`
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
  background-color: ${({ transparent }) => (transparent ? 'transparent' : '#121212')}; // Background color of the header
  backdrop-filter: ${({ transparent }) => (transparent ? 'blur(10px)' : 'none')}; // Blur effect when transparent
  border-bottom: 1px solid #252525; // Border color and width at the bottom
  font-weight: 900; // Boldness of the text
`;

const NavButton = styled(Button)`
  color: #ffffff; // Text color for the buttons
  margin-left: 16px; // Spacing between buttons
  font-family: 'Inter', sans-serif; // Specific font for buttons
  font-weight: 500; // Boldness of the text
  font-size: 20px; // Font size for buttons
  line-height: 23.2px; // Line height for buttons
  text-align: center; // Center align the text

  &:hover {
    color: #4f46e5; // Change color on hover
  }
`;

function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to manage login status
  const [drawerOpen, setDrawerOpen] = useState(false); // State to manage drawer open/close
  const isMobile = useMediaQuery('(max-width:600px)'); // Detect if screen size is mobile
  const location = useLocation(); // Get the current location
  const navigate = useNavigate(); // Get the navigate function
  const [transparent, setTransparent] = useState(true); // State to manage header transparency

  useEffect(() => {
    const verifyToken = () => {
      const token = Cookies.get('chattoken'); // Get the token from cookies
      console.log('Verifying token...'); // Log to confirm function call
    
      if (token) {
        console.log('Token found:', token); // Log the token value
        // Send API request to verify the token
        axios.get('https://api.estopia.net/api/auth/verifyToken', { withCredentials: true })
          .then(response => {
            if (response.data.valid) {
              console.log('Token is valid'); // Log valid token
              setIsLoggedIn(true); // Set login status to true if token is valid
            } else {
              console.log('Token is invalid'); // Log invalid token
              setIsLoggedIn(false); // Set login status to false if token is invalid
              if (location.pathname.includes('/dashboard')) {
                window.location.href = '/login'; // Redirect to login page if not logged in
                console.log('Redirecting to login page...'); // Log to confirm redirection
              }
            }
          })
          .catch(error => {
            console.error('Error verifying token:', error);
            setIsLoggedIn(false); // Set login status to false if there's an error
            if (location.pathname.includes('/dashboard')) {
              window.location.href = '/login'; // Redirect to login page if not logged in
              console.log('Redirecting to login page...'); // Log to confirm redirection
            }
          });
      } else {
        console.log('No token found');
        setIsLoggedIn(false); // Set login status to false if no token is found
        if (location.pathname.includes('/dashboard')) {
          window.location.href = '/login'; // Redirect to login page if not logged in
          console.log('Redirecting to login page...'); // Log to confirm redirection
        }
      }
    };

    verifyToken(); // Call the function to verify the token
  }, [location]); // Run the effect whenever the token changes

  const logout = () => {
    axios.get('https://api.estopia.net/api/auth/logout', { withCredentials: true }) // Send API request to logout
      .then(() => {
        setIsLoggedIn(false); // Set login status to false
        navigate('/login'); // Navigate to login page
        Cookies.remove('chattoken'); // Remove the token from cookies
      })
      .catch(error => {
        console.error('Error logging out:', error); // Log any errors
      });
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setTransparent(true);
      } else {
        setTransparent(false);
      }
    };
  
    // Check scroll position on mount
    handleScroll();
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = (
    <Box
      sx={{ width: 250, backgroundColor: '#0D0D0D', height: '100%' }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {isLoggedIn ? (
          <>
            <ListItem component={Link} to="/home">
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem  component={Link} to="/discovery">
              <ListItemText primary="Discovery" />
            </ListItem>
            <ListItem component={Link} to="/settings">
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={logout}>
              <ListItemText primary="Logout" />
            </ListItem>
          </>
        ) : (
          <>
            <ListItem component={Link} to="/login">
              <ListItemText primary="Login" />
            </ListItem>
            <ListItem component={Link} to="/register">
              <ListItemText primary="Register" />
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  return (
    <StyledAppBar position="fixed" transparent={transparent}>
      <Toolbar>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            {/* Left Side: Logo/Brand */}
            <Box component={Link} to="/" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
              <img src={Logo} alt="Estopia Chatting Logo" style={{ height: isMobile ? '30px' : '40px' }} />
            </Box>
            {/* Right Side: Navigation Links */}
            {isMobile ? (
              <>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                  <MenuIcon />
                </IconButton>
                <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                  {drawerList}
                </Drawer>
              </>
            ) : (
              <Box>
                {isLoggedIn ? (
                  <>
                    <NavButton component={Link} to="/discovery">
                      Discovery
                    </NavButton>
                    <NavButton component={Link} to="/home">
                      Home
                    </NavButton>
                    <NavButton component={Link} to="/settings">
                      <SettingsIcon />
                    </NavButton>
                  </>
                ) : (
                  <NavButton component={Link} to="/login">
                    Login
                  </NavButton>
                )}
              </Box>
            )}
          </Box>
      </Toolbar>
    </StyledAppBar>
  );
}

export default Header;
