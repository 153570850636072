import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, Typography, useMediaQuery, TextField, Grid, IconButton } from '@mui/material';
import { Route, useNavigate, Routes, useLocation } from 'react-router-dom';
import { StyledButton } from '../../components/StyledComponents';
import CreateChannel from './CreateChannel';
import axios from 'axios';
import { Delete, VisibilityOff, EditOff, ExitToApp, Save } from '@mui/icons-material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChatScreen from './ChatScreen';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ServerChannels = ({ SelectedServer, isAdmin, isOwner }) => {
    const [channels, setChannels] = useState(null);
    const [SelectedChannel, setSelectedChannel] = useState(null)
    const isMobile = useMediaQuery('(max-width: 750px)');
    const navigate = useNavigate()
    const location = useLocation();

    const fetchChannels = useCallback(() => {
        axios.get(`https://api.estopia.net/api/channels/${SelectedServer._id}`, {
            withCredentials: true
        })
            .then(response => {
                setChannels(response.data);
            })
            .catch(error => {
                console.error(error);
            }
        );
    }, [SelectedServer]);

    const updateChannels = () => {
        axios.get(`https://api.estopia.net/api/channels/${SelectedServer._id}`, {
            withCredentials: true
        })
            .then(response => {
                setChannels(response.data);
            })
            .catch(error => {
                console.error(error);
            }
        );
    }

    useEffect(() => {
        fetchChannels();
    }, [fetchChannels]);

    const channelMatch = location.pathname.match(/^\/home\/server\/([^/]+)\/channel\/([^/]+)/);
    if(!SelectedChannel && channelMatch) {
        if (channels) {
            const selectedChannelId = channelMatch ? channelMatch[2] : null;
            const selectedChannel = channels.find(channel => channel._id === selectedChannelId);
            if (selectedChannel) {
                setSelectedChannel(selectedChannel);
            } else {
                navigate('/home')
                return (
                    <Typography>Loading...</Typography>
                );
            }
        } else {
            return (
                <Typography>Loading...</Typography>
            );
        }
    } else {
        if(channels && !SelectedChannel && !isMobile && channels.length > 0) {
            setSelectedChannel(channels[0]);
        }
    }

    const handleChannelSelect = (channel) => {
        navigate(`/home/server/${SelectedServer._id}/channel/${channel._id}`)
        setSelectedChannel(channel)
    }

    if(isMobile) {
        return (
            <Routes>
                <Route path="/" element={
                    <Box sx={{ display: 'flex', height: '100vh' }}>
                        <Box sx={{ width: '100%', backgroundColor: '#121212', padding: 2, borderRight: '1px solid #ddd' }}>
                            <Typography variant="h6" sx={{ color: '#ffffff', marginBottom: 2 }}>Channels</Typography>
                            {channels ? (
                                channels.map(channel => (
                                    <Box key={channel._id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1, borderBottom: '1px solid #ddd' }}>
                                        <Button onClick={() => handleChannelSelect(channel)} sx={{ color: '#ffffff', textDecoration: 'none', background: 'none', border: 'none', cursor: 'pointer' }}>
                                            {channel.name} 
                                            {channel.staffViewOnly ? (
                                                <VisibilityOff sx={{ fontSize: '1em', marginLeft: '0.15em' }} />
                                            ) : channel.staffTextOnly ? (
                                                <EditOff sx={{ fontSize: '1em', marginLeft: '0.15em' }} />
                                            ) : null}
                                        </Button>
                                        {isAdmin && (
                                            <Button
                                                onClick={() => {
                                                    if (window.confirm(`Are you sure you want to remove the channel ${channel.name}?`)) {
                                                        axios.delete(`https://api.estopia.net/api/channels/${channel._id}`, {
                                                            withCredentials: true
                                                        })
                                                        .then(response => {
                                                            updateChannels();
                                                        })
                                                        .catch(error => {
                                                            console.error(error);
                                                        });
                                                    }
                                                }}
                                                sx={{ color: '#ffffff', textDecoration: 'none', background: 'none', border: 'none', cursor: 'pointer' }}
                                            >
                                                <Delete />
                                            </Button>
                                        )}
                                    </Box>
                                ))
                            ) : (
                                <Typography sx={{ color: '#ffffff' }}>No Channels</Typography>
                            )}
                            {isAdmin && (
                                <StyledButton
                                    variant="contained"
                                    color="primary"
                                    sx={{ marginTop: 2 }}
                                    onClick={() => { navigate(`/home/server/${SelectedServer._id}/Add`) }}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </StyledButton>
                            )}
                        </Box>
                    </Box>
                } />
                <Route path="/Add" element={<CreateChannel selectedServer={SelectedServer} updateChannels={updateChannels}/>} />
                <Route path="/channel/*" element={ SelectedChannel && <ChatScreen selectedChannel={SelectedChannel} isAdmin={isAdmin}/>} />
            </Routes>

        )
    }
    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ width: '350px', backgroundColor: '#121212', padding: 2, borderRight: '1px solid #ddd' }}>
                <Typography variant="h6" sx={{ color: '#ffffff', marginBottom: 2 }}>Channels</Typography>
                {channels ? (
                    channels.map(channel => (
                        <Box key={channel._id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1, borderBottom: '1px solid #ddd' }}>
                            <Button onClick={() => handleChannelSelect(channel)} sx={{ color: '#ffffff', textDecoration: 'none', background: 'none', border: 'none', cursor: 'pointer' }}>
                                {channel.name} 
                                {channel.staffViewOnly ? (
                                    <VisibilityOff sx={{ fontSize: '1em', marginLeft: '0.15em' }} />
                                ) : channel.staffTextOnly ? (
                                    <EditOff sx={{ fontSize: '1em', marginLeft: '0.15em' }} />
                                ) : null}
                            </Button>
                            {isAdmin && (
                                <Button
                                    onClick={() => {
                                        if (window.confirm(`Are you sure you want to remove the channel ${channel.name}?`)) {
                                            axios.delete(`https://api.estopia.net/api/channels/${channel._id}`, {
                                                withCredentials: true
                                            })
                                            .then(response => {
                                                updateChannels();
                                            })
                                            .catch(error => {
                                                console.error(error);
                                            });
                                        }
                                    }}
                                    sx={{ color: '#ffffff', textDecoration: 'none', background: 'none', border: 'none', cursor: 'pointer' }}
                                >
                                    <Delete />
                                </Button>
                            )}
                        </Box>
                    ))
                ) : (
                    <Typography sx={{ color: '#ffffff' }}>No Channels</Typography>
                )}
                
                {isAdmin && (
                    <StyledButton
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: 2 }}
                        onClick={() => {navigate(`/home/server/${SelectedServer._id}/Add`)}}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </StyledButton>
                )}
                    
            </Box>
            <Box sx={{ flexGrow: 1, maxheight: 'calc(100vh + 64px)', height: 'calc(100vh - 69.5px)'  }}>
                <Routes>
                    <Route path="/Add" element={<CreateChannel selectedServer={SelectedServer} updateChannels={updateChannels} />} />
                    <Route path="/channel/*" element={ SelectedChannel && <ChatScreen selectedChannel={SelectedChannel} isAdmin={isAdmin}/>} />
                </Routes>
            </Box>
        </Box>
    )
}

const ServerMembers = ({ SelectedServer, isAdmin, isOwner }) => {
    const [members, setMembers] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState(members);
    const [usernameFilter, setUsernameFilter] = useState('');
    const [genderFilter, setGenderFilter] = useState('');
    const [roleFilter, setRoleFilter] = useState('');
    const [selectedMember, setSelectedMember] = useState(null);

    const handleDropdownToggle = (member) => {
        if (member.id === selectedMember?.id) {
            setSelectedMember(null);
        } else {
            setSelectedMember(member);
        }
    };

    const handleKick = () => {
        if(!window.confirm(`Are you sure you want to kick ${selectedMember.username}?`)) return;
        axios.delete(`https://api.estopia.net/api/servers/members`, {
            data: {
                server_id: SelectedServer._id,
                user_id: selectedMember.id
            },
            withCredentials: true
        })
            .then(response => {
                console.log(response.data);
                fetchMembers();
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleBan = () => {
        if(!window.confirm(`Are you sure you want to ban ${selectedMember.username}?`)) return;
        axios.delete(`https://api.estopia.net/api/servers/members/ban`, {
            data: {
                server_id: SelectedServer._id,
                user_id: selectedMember.id
            },
            withCredentials: true
        })
            .then(response => {
                console.log(response.data);
                fetchMembers();
            })
            .catch(error => {
                console.error(error);
            });
    };

    const fetchMembers = useCallback(() => {
        axios.get(`https://api.estopia.net/api/servers/members/${SelectedServer._id}`, {
            withCredentials: true
        })
            .then(response => {
                setMembers(response.data.memberDetails);
            })
            .catch(error => {
                console.error(error);
            }
        );
    }, [SelectedServer]);

    const updateMembers = (newRole, member) => {
        console.log(newRole, member);
        if(newRole === member.role) return;
        if(member.role === 'owner') {
            window.alert('You cannot change the role of the owner, You can only transfer ownership to another member');
            return;
        }
        if(!window.confirm(`Are you sure you want to change ${member.username}'s role to ${newRole}?`)) return;
        if(newRole === 'owner') {
            if(!window.confirm('Are you sure you want to change this user to owner? This will remove your ownership')) return;
        }
        axios.put(`https://api.estopia.net/api/servers/members/role`, {
            server_id: SelectedServer._id,
            user_id: member.id,
            role: newRole
        }, {
            withCredentials: true
        })
            .then(response => {
                console.log(response.data);
                fetchMembers();
            })
            .catch(error => {
                console.error(error);
            });
    };

    useEffect(() => {
        fetchMembers();
    }, [fetchMembers]);

    useEffect(() => {
        let filtered = members;

        if (usernameFilter) {
            filtered = filtered.filter(member => member.username.toLowerCase().includes(usernameFilter.toLowerCase()));
        }

        if (genderFilter) {
            filtered = filtered.filter(member => member.gender === genderFilter);
        }

        if (roleFilter) {
            filtered = filtered.filter(member => member.role === roleFilter);
        }

        setFilteredMembers(filtered);
    }, [usernameFilter, genderFilter, roleFilter, members]);
    
    return (
        <Box sx={{height: '100vh'}}>
            <Typography variant="h5" gutterBottom>Members</Typography>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                <TextField
                    label="Username"
                    id='username-filter'
                    variant="outlined"
                    value={usernameFilter}
                    onChange={(e) => setUsernameFilter(e.target.value)}
                    sx={{ input: { color: '#ffffff' } }}
                    fullWidth
                />
                <TextField
                    label="Gender"
                    id='gender-filter'
                    variant="outlined"
                    value={genderFilter}
                    onChange={(e) => setGenderFilter(e.target.value)}
                    sx={{ input: { color: '#ffffff' } }}
                    fullWidth
                />
                <TextField
                    label="Role"
                    id='role-filter'
                    variant="outlined"
                    value={roleFilter}
                    onChange={(e) => setRoleFilter(e.target.value)}
                    sx={{ input: { color: '#ffffff' } }}
                    fullWidth
                />
            </Box>
            <Grid container spacing={2}>
                {filteredMembers.map(member => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={member._id}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2, border: '1px solid #ddd', borderRadius: 2 }}>
                            <Box key={member._id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="h6">{member.username}</Typography>
                                { isAdmin && (
                                    <Box sx={{ position: 'relative' }}>
                                        <IconButton onClick={() => handleDropdownToggle(member)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        {member.id === selectedMember?.id && (
                                            <Box sx={{ position: 'absolute', top: '100%', left: 0, boxShadow: 3, borderRadius: 1, zIndex: 1000 }}>
                                            <Button
                                                sx={{
                                                    backgroundColor: '#121212',
                                                    color: '#ffffff',
                                                    borderRadius: '0',
                                                    '&:hover': {
                                                        backgroundColor: '#333333',
                                                    },
                                                }}
                                                onClick={() => handleKick(member)}
                                            >
                                                Kick
                                            </Button>
                                            <Button
                                                sx={{
                                                    backgroundColor: '#121212',
                                                    color: '#ffffff',
                                                    borderRadius: '0',
                                                    '&:hover': {
                                                        backgroundColor: '#333333',
                                                    },
                                                }}
                                                onClick={() => handleBan(member)}
                                            >
                                                Ban
                                            </Button>
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </Box>
                            {member.dob && <Typography>DOB: {new Date(member.dob).toLocaleDateString()}</Typography>}
                            {member.gender && <Typography>Gender: {member.gender}</Typography>}
                            <Typography>Role: {member.role}</Typography>
                            <Typography>Join Date: {new Date(member.join_date).toLocaleDateString()}</Typography>
                            <Typography>Role Update Date: {new Date(member.role_updated).toLocaleDateString()}</Typography>
                            {isOwner && (
                                <TextField
                                    select
                                    label="Change Role"
                                    value={member.role}
                                    onChange={(e) => {
                                        const newRole = e.target.value;
                                        updateMembers(newRole, member);
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    sx={{ marginTop: 2 }}
                                >
                                    <option value="member">Member</option>
                                    <option value="admin">Admin</option>
                                    <option value="owner">Owner</option>
                                </TextField>
                            )}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

const ServerSettings = ({ SelectedServer, isAdmin, isOwner }) => {
    const navigate = useNavigate();

    const [serverName, setServerName] = useState('');
    const [serverDescription, setServerDescription] = useState('');
    const [serverMinAge, setServerMinAge] = useState('');
    const [serverMaxAge, setServerMaxAge] = useState('');
    const [serverGenderRequirement, setServerGenderRequirement] = useState('');
    const [privateServer, setPrivateServer] = useState(false);
    const [bans, setBans] = useState([]);

    const getBans = useCallback(() => {
        axios.get(`https://api.estopia.net/api/servers/bans/${SelectedServer._id}`, {
            withCredentials: true
        })
            .then(response => {
                setBans(response.data.bannedUsers);
            })
            .catch(error => {
                console.error(error);
            });
    }, [SelectedServer._id]);

    useEffect(() => {
        if(isAdmin){
            getBans();
        }
    }, [getBans, isAdmin]);


    const updateServerDetails = useCallback(() => {
        setServerName(SelectedServer.name);
        setServerDescription(SelectedServer.description);
        setServerMinAge(SelectedServer.min_age);
        setServerMaxAge(SelectedServer.max_age);
        setServerGenderRequirement(SelectedServer.gender_requirement);
        setPrivateServer(SelectedServer.private);
    }, [SelectedServer]);

    useEffect(() => {
        updateServerDetails();
    }, [SelectedServer, updateServerDetails]);

    const leaveServer = () => {
        if (window.confirm('Are you sure you want to leave this server? You can rejoin later if you wish.')) {
            console.log('Leave Server');
            axios.delete(`https://api.estopia.net/api/servers/leave`, {
                data: { server_id: SelectedServer._id },
                withCredentials: true
            })
                .then(response => {
                    console.log(response.data);
                    navigate('/home');
                })
                .catch(error => {
                    console.error(error);
                }
            );
        }
    };

    const deleteServer = () => {
        if (window.confirm('Are you sure you want to delete this server? This action cannot be undone.')) {
            console.log('Delete Server');
            axios.delete(`https://api.estopia.net/api/servers`, {
                data: { server_id: SelectedServer._id },
                withCredentials: true
            })
                .then(response => {
                    console.log(response.data);
                    navigate('/home');
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };

    const saveChanges = () => {
        if(window.confirm('Are you sure you want to save these changes?, This may kick some members if changing age requirements or gender requirements')) {
            console.log('Save Changes');
            axios.put(`https://api.estopia.net/api/servers`, {
                server_id: SelectedServer._id,
                name: serverName,
                description: serverDescription,
                min_age: serverMinAge,
                max_age: serverMaxAge,
                private: privateServer,
                gender_requirement: serverGenderRequirement
            }, {
                withCredentials: true
            })
                .then(response => {
                    console.log(response.data);
                    updateServerDetails();
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };

    const handleUnban = (banId) => {
        if(window.confirm('Are you sure you want to unban this user?')) {
            axios.delete(`https://api.estopia.net/api/servers/bans`, {
                data: {
                    server_id: SelectedServer._id,
                    user_id: banId
                },
                withCredentials: true
            })
                .then(response => { 
                    console.log(response.data);
                    getBans();
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };
     
    const server = SelectedServer;
    return (
        <Box padding={2} sx={{height: '100vh'}}>
            <Typography variant="h4" gutterBottom>Settings</Typography>
            {server ? (
                <>
                    {isAdmin ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 2 }}>
                            <TextField
                                label="Server Name"
                                variant="outlined"
                                value={serverName}
                                onChange={(e) => setServerName(e.target.value)}
                            />
                            <TextField
                                label="Server Description"
                                variant="outlined"
                                value={serverDescription}
                                onChange={(e) => setServerDescription(e.target.value)}
                            />
                            <Typography sx={{ color: '#ffffff', marginBottom: 1 }}>ServerID: {server._id}</Typography>
                            <Typography sx={{ color: '#ffffff', marginBottom: 1 }}>OwnerID: {server.ownerId}</Typography>
                            <Typography sx={{ color: '#ffffff', marginBottom: 1 }}>Created At: {new Date(server.createdAt).toLocaleString()}</Typography>
                            <Typography sx={{ color: '#ffffff', marginBottom: 1 }}>Last Updated At: {new Date(server.updatedAt).toLocaleString()}</Typography>
                            <TextField
                                label="Min Age"
                                variant="outlined"
                                value={serverMinAge}
                                onChange={(e) => setServerMinAge(e.target.value)}
                            />
                            <TextField
                                label="Max Age"
                                variant="outlined"
                                value={serverMaxAge}
                                onChange={(e) => setServerMaxAge(e.target.value)}
                            />
                            <TextField
                                label="Gender Requirement"
                                variant="outlined"
                                value={serverGenderRequirement}
                                onChange={(e) => setServerGenderRequirement(e.target.value)}
                            />
                            <TextField
                                select
                                label="Private (Hides Server in Discovery)"
                                value={privateServer}
                                onChange={(e) => setPrivateServer(e.target.value)} 
                                SelectProps={{
                                    native: true
                                }}
                            >
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </TextField>
                        </Box>
                    ) : (
                        <>
                            <Typography variant="h6" sx={{ color: '#ffffff', marginBottom: 1 }}>Name: {server.name}</Typography>
                            <Typography sx={{ color: '#ffffff', marginBottom: 1 }}>Description: {server.description}</Typography>
                            <Typography sx={{ color: '#ffffff', marginBottom: 1 }}>ServerID: {server._id}</Typography>
                            <Typography sx={{ color: '#ffffff', marginBottom: 1 }}>OwnerID: {server.ownerId}</Typography>
                            <Typography sx={{ color: '#ffffff', marginBottom: 1 }}>Created At: {new Date(server.createdAt).toLocaleString()}</Typography>
                            <Typography sx={{ color: '#ffffff', marginBottom: 1 }}>Last Updated At: {new Date(server.updatedAt).toLocaleString()}</Typography>
                            {server.min_age && (
                                <Typography sx={{ color: '#ffffff', marginBottom: 1 }}>Min Age: {server.min_age}</Typography>
                            )}
                            {server.max_age && (
                                <Typography sx={{ color: '#ffffff', marginBottom: 1 }}>Max Age: {server.max_age}</Typography>
                            )}
                            {server.gender_requirement && (
                                <Typography sx={{ color: '#ffffff', marginBottom: 1 }}>Gender Requirement: {server.gender_requirement}</Typography>
                            )}
                            {server.private ? (
                                <Typography sx={{ color: '#ffffff', marginBottom: 1 }}>Private: Yes</Typography>
                            ) : (
                                <Typography sx={{ color: '#ffffff', marginBottom: 1 }}>Private: No</Typography>
                            )}
                        </>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    {isAdmin && (
                            <StyledButton variant="contained" color="primary" startIcon={<Save />} onClick={saveChanges}>Save Changes</StyledButton>      
                    )}
                    {isOwner ? (
                        <StyledButton variant="contained" color="error" startIcon={<ExitToApp />} onClick={deleteServer}>Delete Server</StyledButton>
                    ) : (
                        <StyledButton variant="contained" color="secondary" startIcon={<ExitToApp />} onClick={leaveServer}>Leave Server</StyledButton>
                    )}
                    
                    </Box>
                </>
            ) : (
                <Typography>No server details available.</Typography>
            )}
            {isAdmin && bans && (
            <Box>
                <br />
                <Typography variant="h6">Banned Users</Typography>
                {bans.length > 0 ? (
                    bans.map(ban => (
                        <Box key={ban._id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1, borderBottom: '1px solid #ddd' }}>
                            <Typography>{ban.username}</Typography>
                            <Button 
                                onClick={() => handleUnban(ban._id)}
                                variant="contained" 
                                color="primary"
                            >
                                Unban
                            </Button>
                        </Box>
                    ))
                ) : (
                    <Typography>No banned users</Typography>
                )}
            </Box>
            )}
        </Box>
    );
}

const ServerDetails = ({ SelectedServer }) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [isOwner, setIsOwner] = useState(false);

    useEffect(() => {
        axios.get(`https://api.estopia.net/api/servers/perms/${SelectedServer._id}`, {
            withCredentials: true
        })
            .then(response => {
                setIsAdmin(response.data.role === 'admin' || response.data.role === 'owner');
                setIsOwner(response.data.role === 'owner');
            })
            .catch(error => {
                console.error(error);
            }
        )
    }, [SelectedServer]);

    const isMobile = useMediaQuery('(max-width: 750px)');

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2, backgroundColor: '#121212', borderBottom: '1px solid #ddd', margin: 0, marginTop: isMobile ? '55px' : '0px' }}>
                <Typography variant="h6">
                    {SelectedServer.name}
                </Typography>
                <Typography variant="h6">
                    <Button onClick={() => navigate(`/home/server/${SelectedServer._id}`)} style={{ color: '#ffffff' }}>Channels</Button>
                </Typography>
                <Typography variant="h6">
                    <Button onClick={() => navigate(`/home/server/${SelectedServer._id}/members`)} style={{ color: '#ffffff' }}>Members</Button>
                </Typography>
                <Typography variant="h6">
                    <Button onClick={() => navigate(`/home/server/${SelectedServer._id}/settings`)} style={{ color: '#ffffff' }}>Settings</Button>
                </Typography>
            </Box>
            <Box sx={{ maxHeight: '100%', overflowY: 'auto' }}>
                <Routes>
                    <Route path="/members" element={<ServerMembers SelectedServer={SelectedServer} isAdmin={isAdmin} isOwner={isOwner} />} />
                    <Route path="/settings" element={<ServerSettings SelectedServer={SelectedServer} isAdmin={isAdmin} isOwner={isOwner} />} />
                    <Route path="/*" element={<ServerChannels SelectedServer={SelectedServer} isAdmin={isAdmin} isOwner={isOwner} />} />
                </Routes>
            </Box>

        </React.Fragment>
    );
};

export default ServerDetails;