import React, {useEffect} from 'react';
import { Container, Box, Typography, useMediaQuery, Card, CardContent, Divider } from '@mui/material';
import AOS from 'aos';

function Privacy() {
    const isMobile = useMediaQuery('(max-width: 600px)');

    useEffect(() => {
        AOS.init({
          duration: 500, // Animation duration in milliseconds
        });
      }, []);

    return (
        <Container maxWidth="lg" alignSelf="center" sx={{ overflowX: 'hidden', overflowY: 'hidden' }}>
        <Box textAlign="center" padding={isMobile ? "32px" : "64px"}>
          <Typography variant={isMobile ? "h4" : "h3"} data-aos="fade-down" marginTop={isMobile ? "32px" : "64px"}>
            Privacy Policy
          </Typography>
          <Typography variant={isMobile ? "body1" : "h5"} data-aos="fade-up" marginTop={isMobile ? "16px" : "32px"}>
            Please read our Privacy Policy carefully
          </Typography>
        </Box>
        <Card sx={{ marginTop: '32px', padding: '16px', backgroundColor: '#121212' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph>
            We collect and store the following information that you provide:
          </Typography>
          <Typography variant="body1" paragraph>
            - Your email, hashed password, and username.
            - Your account is account with any messages you send and any media you upload.
          </Typography>
          <Divider sx={{ marginY: '16px' }} />
          
          <Typography variant="h5" gutterBottom>
            2. Account Information
          </Typography>
          <Typography variant="body1" paragraph>
            For your account, we store the following:
          </Typography>
          <Typography variant="body1" paragraph>
            - Your email, hashed password, and username.
          </Typography>
          <Divider sx={{ marginY: '16px' }} />
          
          <Typography variant="h5" gutterBottom>
            3. Server Information
          </Typography>
          <Typography variant="body1" paragraph>
            When you add a server, we store the following information:
          </Typography>
          <Typography variant="body1" paragraph>
            - Server name, description, age requirements, Gender Requirements, and anything else you provide when adding a server.
          </Typography>
          <Typography variant="body1" paragraph>
            This information is cached and not permanently saved when prompted to add a server.
          </Typography>
          <Divider sx={{ marginY: '16px' }} />
          
          <Typography variant="h5" gutterBottom>
            4. Data Security
          </Typography>
          <Typography variant="body1" paragraph>
            We prioritize your data security. All sensitive information, such as passwords, is securely stored and hashed.
            Yoy may also request to delete your account and all associated data at any time.
          </Typography>
          <Divider sx={{ marginY: '16px' }} />
          
          <Typography variant="h5" gutterBottom>
            5. Changes to Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to modify this Privacy Policy at any time. Any changes will be communicated via updates on our platform.
          </Typography>
        </CardContent>
      </Card>
    </Container>
    );

}

export default Privacy;