import { styled, MenuItem, FormControl, TextField as MuiTextField, Button } from '@mui/material';

// Styled Button
const StyledButton = styled(Button)`
  border-radius: 8px;
  margin-top: 20px;
  background-color: #4D44DF;
  border: 2px solid #4D44DF;
  padding: 10px 20px;
  color: white;
  &:hover {
    background-color: #0A0A0A;
  }
`;

// Styled TextField
const StyledTextField = styled(MuiTextField)`
  .MuiInputBase-input {
    color: white;
    fontFamily: 'Inter, Arial, sans-serif';
  }
  .MuiInputBase-input::placeholder {
    color: #ADADAD;
  }
  .MuiOutlinedInput-root {
    border-radius: 8px;
    & fieldset {
      border-color: #212121;
    }
    &:hover fieldset {
      border-color: #4f46e5;
    }
    &.Mui-focused fieldset {
      border-color: #4f46e5;
    }
  }
  .MuiInputLabel-root {
    color: #9E9E9E;
    fontFamily: 'Inter, Arial, sans-serif';
  }
  .MuiInputLabel-root.Mui-focused {
    color: #4f46e5;
  }
`;

// Styled FormControl
const StyledFormControl = styled(FormControl)`
  .MuiInputBase-input {
    color: white;
    fontFamily: 'Inter, Arial, sans-serif';
  }
  .MuiInputBase-input::placeholder {
    color: #ADADAD;
  }
  .MuiOutlinedInput-root {
    border-radius: 8px;
    background-color: black;
    & fieldset {
      border-color: #212121;
    }
    &:hover fieldset {
      border-color: #4f46e5;
    }
    &.Mui-focused fieldset {
      border-color: #4f46e5;
    }
  }
  .MuiInputLabel-root {
    color: #9E9E9E;
    font-family: 'Inter, Arial, sans-serif';
  }
  .MuiInputLabel-root.Mui-focused {
    color: #4f46e5;
  }
  .MuiSelect-icon {
    color: white;
  }
  font-family: 'Inter, Arial, sans-serif';
`;

// Styled MenuItem
const StyledMenuItem = styled(MenuItem)`
  color: white;
  background-color: black;
  &:hover {
    background-color: #4f46e5;
  }
  &.Mui-selected {
    background-color: #4f46e5 !important;
    color: white;
  }
  font-family: 'Inter, Arial, sans-serif';
`;

export { StyledButton, StyledTextField, StyledFormControl, StyledMenuItem };