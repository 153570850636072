// src/theme.js
import { createTheme } from '@mui/material/styles';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#0a0a0a', // Sets the default background color
    },
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif', // Global font for all typography
    h3: {
        fontFamily: 'Inter, sans-serif', // Specific font for h3
        fontWeight: 700, // Boldness of the text
        fontSize: '56px', // Font size for h3
        lineHeight: '65.352px', // Line height for h3
        textAlign: 'center', // Center align the text
        color: '#FFFFFF', // Text color for h3
    },
    h5: {
        color: '#FFFFFF', // Text color for h5
    },
    button: {
        fontFamily: 'Inter, sans-serif', // Font for buttons
        textTransform: 'none', // Prevents all caps on buttons
    },
    body1: {
        color: '#FFFFFF', // Text color for body1
    },
  },
  root: {
    color: '#FFFFFF', // Global text color
  },
});

export default theme;
