import React from 'react';
import { Box, Typography } from '@mui/material';
import { StyledButton, StyledTextField } from '../../components/StyledComponents';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const JoinServer = ({ joinServerId, setJoinServerId }) => {
    const navigate = useNavigate();

    const JoinServer = () => {
        if(!joinServerId) {
            return;
        }

        axios.post('https://api.estopia.net/api/servers/join', {
            server_id: joinServerId
        }, {
            withCredentials: true
        })
            .then(response => {
                console.log(response.data);
                navigate('/home');
            })
            .catch(error => {
                console.error(error);
                if(error.response.status === 404) {
                    alert('Server not found');
                }
        });
    };

    return (
        <Box sx={{ width: '100%', height: '100vh', padding: 2, marginTop: "64px" }}>
            <Typography variant="h4" textAlign="center" marginBottom="32px">Join a server:</Typography>
            <StyledTextField
                label="Server ID"
                variant="outlined"
                fullWidth
                value={joinServerId}
                onChange={(e) => setJoinServerId(e.target.value)}
            />
            <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={JoinServer}
            >
                Join
            </StyledButton>
        </Box>
    );
};

export default JoinServer;