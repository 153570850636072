import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, List, ListItem, ListItemText, CircularProgress, Box, Paper, Container } from '@mui/material';
import { StyledButton } from '../components/StyledComponents';
import { useNavigate } from 'react-router-dom';

const Discovery = () => {
    const [servers, setServers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchServers = async () => {
            try {
                const response = await axios.get('https://api.estopia.net/api/servers/available', {
                    withCredentials: true
                });
                console.log(response.data); // Log the structure of the response data
                setServers(response.data.servers || []); // Adjust this line based on the logged structure
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchServers();
    }, []);

    const JoinServer = (id) => {
        console.log('Joining server:', id);
        axios.post('https://api.estopia.net/api/servers/join', {
            server_id: id
        }, {
            withCredentials: true
        })
            .then(response => {
                console.log(response.data);
                navigate(`/home/server/${id}`);
            })
            .catch(error => {
                console.error(error);
                if (error.response.status === 404) {
                    alert('Server not found');
                }
                if (error.response.status === 400) {
                    navigate(`/home/server/${id}`);
                }
            }
        );
    };

    if (loading) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;
    if (error) return <Typography color="error">Error: {error}</Typography>;

    console.log(servers);

    return (
        <Container>
            <Box padding={2}>
                <Typography variant="h4" marginTop="64px" gutterBottom>Available Servers</Typography>
                <List>
                    {Array.isArray(servers) && servers.map((server) => {
                        const createdAt = new Date(server.createdAt);
                        const formattedDate = `${createdAt.getMonth() + 1}/${createdAt.getDate()}/${createdAt.getFullYear()}`;
                        return (
                            <Box key={server.id} component={Paper} elevation={3} padding={2} marginBottom={2} sx={{backgroundColor: '#121212'}}>
                                <ListItem>
                                    <ListItemText 
                                        primary={server.name} 
                                        secondary={`${server.description}\nCreated on: ${formattedDate}`} 
                                        sx={{ color: 'white' }}
                                        primaryTypographyProps={{ style: { color: 'white' } }}
                                        secondaryTypographyProps={{ style: { color: 'white' } }}
                                    />
                                    {server.joined ?                                     
                                        <StyledButton onClick={() => JoinServer(server._id)}>
                                            View
                                        </StyledButton> :
                                        <StyledButton onClick={() => JoinServer(server._id)}>
                                            Join
                                        </StyledButton>
                                    }
                                </ListItem>
                            </Box>
                        );
                    })}
                </List>
            </Box>
        </Container>
    );
};

export default Discovery;