import React, {useEffect} from 'react';
import { Container, Box, Typography, useMediaQuery, Button, Card, CardActions, Grid, CardContent, Divider } from '@mui/material';
import AOS from 'aos';

const ContactWays = [
    {
        title: 'Email',
        description: 'Contact us via email',
        uses: ['Fast response time', '24/7 availability', 'Professional support'],
        link: 'mailto:support@estopia.net',
        buttonText: 'Send Email',
    },
    {
        title: 'Discord',
        description: 'Join our Discord server',
        uses: ['Community support', 'Live chat', 'Instant help'],
        link: 'https://discord.gg/your-server-link',
        buttonText: 'Join Discord',
    },
];
    
function Support() {
    const isMobile = useMediaQuery('(max-width: 600px)');

    useEffect(() => {
        AOS.init({
          duration: 500, // Animation duration in milliseconds
        });
      }, []);

    return (
        <Container maxWidth="lg" alignSelf="center" sx={{ overflowX: 'hidden', overflowY: 'hidden' }}>
            <Box textAlign="center" padding={isMobile ? "32px" : "64px"}>
            <Typography variant={isMobile ? "h4" : "h3"} data-aos="fade-down" marginTop={isMobile ? "32px" : "64px"}>
                Support
            </Typography>
            <Typography variant={isMobile ? "body1" : "h5"} data-aos="fade-up" marginTop={isMobile ? "16px" : "32px"}>
                Get in touch with us
            </Typography>
            </Box>
            <Grid container spacing={4} justifyContent="center">
                {ContactWays.map((ContactWay, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card data-aos="fade-up" sx={{ backgroundColor: '#121212', borderRadius: '8px', padding: '8px' }}>
                    <CardContent>
                        <Typography variant="h5" component="div" align="center">
                        {ContactWay.title}
                        </Typography>
                        <Typography variant="h5" align="center" color="#4f46e5" gutterBottom>
                        {ContactWay.description}
                        </Typography>
                        <Divider />
                        <ul>
                        {ContactWay.uses.map((feature, i) => (
                            <li style={{ color: '#FFFFFF' }} key={i}>{feature}</li>
                        ))}
                        </ul>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button href={ContactWay.link} variant="contained" sx={{ backgroundColor: "#4f46e5" }} size="large">
                            {ContactWay.buttonText}
                        </Button>
                    </CardActions>
                    </Card>
                </Grid>
                ))}
            </Grid>
            
        </Container>
    );

}

export default Support;