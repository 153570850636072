import React, { useState } from 'react';
import { Box, Typography, useMediaQuery, Grid } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { StyledTextField, StyledButton } from '../components/StyledComponents';
import axios from 'axios';
import GoogleLogo from '../images/google.svg';
import DiscordLogo from '../images/discord.svg';

const NodeENV = process.env.NODE_ENV;

const Login = () => {
  console.log(NodeENV)
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const redirect = params.get('redirect') || '/home';

  console.log(redirect)

  const handleLogin = async () => {
    try {
      const currentUrl = window.location.href;
      await axios.post('https://api.estopia.net/api/auth/login', 
          { username, password, redirect: `${currentUrl}/home` },
          { withCredentials: true }
      );
      navigate(redirect);
    } catch (error) {
        if (error.response) {
          if(error.response.status === 401) {
            alert('Invalid username or password. Please try again.');
          } else {
            if(error.response.status === 404){
              alert('Please check your username and password and try again.');
            } else {
              if(error.response.status === 405){
                alert('This Account Doesn\' Support Passoword Authentication, Please use Outh');
              } else {
                alert('An error occurred. Please try again later.');
              }
            }
          }
        } else if (error.request) {
            console.log(error)
            alert('No response from the server. Please try again later.');
        } else {
            alert('An error occurred. Please try again later.');
        }
    }
  };

  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      height="90vh"
    >
      <Typography variant="h3" sx={{ fontSize: '35px' }}>
        Log into your Account
      </Typography>
      <Box width={ isMobile ? "90%" : "35%"} display="flex" flexDirection="column">
        <StyledTextField
          label="Username *"
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
        />
        <StyledTextField
          label="Password *"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
          placeholder="Password"
        />
        <Grid container spacing={2} marginTop={2} alignItems="center">
          <Grid item xs={1}>
            <a 
              href={`https://accounts.google.com/o/oauth2/v2/auth?client_id=287520798259-omo7atod34av19phtb2dq8irokp7i5ge.apps.googleusercontent.com&redirect_uri=https://api.estopia.net/auth/google/${NodeENV !== 'development' ? "Chat" : "Test"}&response_type=code&scope=profile%20email&prompt=consent`}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', margin: '8px 0' }}
            >
              <img src={GoogleLogo} alt="Google" style={{ height: '40px', width: 'auto' }} />
            </a>
          </Grid>
          <Grid item xs={1}>
            <a 
              href={`https://discord.com/oauth2/authorize?client_id=1268751379581894768&response_type=code&redirect_uri=https%3A%2F%2Fapi.estopia.net%2Fauth%2Fdiscord%2F${NodeENV !== 'development' ? "Chat" : "Test"}&scope=identify+email`}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', margin: '8px 0' }}
            >
              <img src={DiscordLogo} alt="Discord" style={{ height: '40px', width: 'auto' }} />
            </a>
          </Grid>
          <Grid item xs={10}>
            <StyledButton 
              variant="contained" 
              onClick={handleLogin}
              fullWidth
              sx={{ margin: '8px 0', height: '40px' }}
            >
              LOGIN
            </StyledButton>
          </Grid>
        </Grid>
        <Typography variant="body2" sx={{ marginTop: '10px', textAlign: 'right' }}>
              <Grid container justifyContent="space-between" alignItems="center" sx={{ marginTop: '10px' }}>
                  <Grid item>
                      <Link to="/privacy" style={{ textDecoration: 'none', color: '#D0ADF0', marginRight: '10px' }}>
                          Privacy Policy
                      </Link>
                      <Link to="/tos" style={{ textDecoration: 'none', color: '#D0ADF0' }}>
                          Terms of Service
                      </Link>
                  </Grid>
                  <Grid item>
                    <Link to={`/register?redirect=${redirect}`} style={{ textDecoration: 'none', color: '#D0ADF0' }}>
                      Don't have an account? Sign Up
                    </Link>
                  </Grid>
              </Grid>
          </Typography>
      </Box>
    </Box>
  );
};

export default Login;
