import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Card as MuiCard, Box, Typography, Button, useMediaQuery, CardContent, List, ListItem, Grid } from '@mui/material';
import styled from '@emotion/styled';
import StarIcon from '@mui/icons-material/Star';
import { Link } from 'react-router-dom';

const Container = styled(Box)`
  text-align: center;
  padding: 50px;
`;

// Custom styled components
const UnderlinedText = styled('span')`
  font-weight: 900;
`;

const HighlightedText = styled('span')`
  background-color: #f44336;
  border-radius: 8px;
  padding: 0 4px;
`;
//4f46e5
const StyledButton = styled(Button)`
  margin-top: 40px; // Increased margin-top for more space from the top
  background-color: #4f46e5;
  font-size: 1em; // Increased font size for better visibility
  color: white;
  border-radius: 8px; // More rounded corners
  position: relative; // Needed for absolute positioning of FreeBubble
  padding: 10px 20px; // Adjust padding for better appearance
  &:hover {
    background-color: #3e3abf; // Slightly darker color for hover state
  }
`;

const Card = ({ title, fadetype, description, extra }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    padding="40px"
    flex={1}
    sx={extra}
    data-aos={fadetype}
    backgroundColor="#4f46e5"
    borderRadius="8px"
    width="100%" // Ensure cards take up full width
  >
    <Typography variant="h6" marginTop="10px" fontWeight="bold">
      {title}
    </Typography>
    <Typography variant="body1" marginTop="10px">
      {description}
    </Typography>
  </Box>
);

function App() {
  const isMobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    AOS.init({
      duration: 500, // Animation duration in milliseconds
    });
  }, []);

  return (
    <Container maxWidth="lg" alignSelf="center" sx={{ overflowX: 'hidden', overflowY: 'hidden' }}>
      <Box textAlign="center" padding={isMobile ? "32px" : "64px"}>
        <Typography variant={isMobile ? "h4" : "h3"} data-aos="fade-down">
          Hello, This is <UnderlinedText>Estopia</UnderlinedText> Chatting
          {isMobile ? " " : <br />}
          <HighlightedText>Hope</HighlightedText> you have fun!
        </Typography>
        <Typography variant={isMobile ? "body1" : "h5"} data-aos="fade-up" marginTop={isMobile ? "16px" : "32px"}>
          The only Communiction Platform that respects your privacy<br />and keeps your data safe while listening to your feedback
        </Typography>
        <Link to="/login" sx={{backgroundColor: '#4f46e5'}}>
          <StyledButton variant="contained" data-aos="zoom-in-up" size={isMobile ? "small" : "large"}>
            Login to Get Started
          </StyledButton>
        </Link>
      </Box>
      
      {/*
        !isMobile && (
              <Box
                component="img"
                src={white}
                width={isMobile ? "100%" : "960px"}
                height={isMobile ? "auto" : "540px"}
                data-aos="zoom-in-up"
              />
            )
          */}
      


      <Box sx={{ marginTop: '64px' }}>
        <MuiCard data-aos={ isMobile ? "fade-left" : "fade-up" } sx={{ backgroundColor: '#121212', borderRadius: '8px' }}>
          <CardContent>
            <Typography variant="h4" component="div" align="center" color="white" sx={{ fontWeight: "700", fontSize: isMobile ? "25px" : "45px" }}>
              Why Estopia Chatting?
            </Typography>
            <List>
              <ListItem>
                <Typography variant={isMobile ? "body1" : "h5"} style={{ color: '#FFFFFF' }}>
                  - The best at Age Verification
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant={isMobile ? "body1" : "h5"} style={{ color: '#FFFFFF' }}>
                   - Safely limit your server to any age range
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant={isMobile ? "body1" : "h5"} style={{ color: '#FFFFFF' }}>
                   - 24/7 Email Support for all your queries
                </Typography>
              </ListItem>
            </List>
          </CardContent>
        </MuiCard>
      </Box>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="center"
        alignItems="stretch" // Ensure all cards have equal height
        marginTop={isMobile ? '16px' : '32px'} // Add margin-top for better spacing
        gap={isMobile ? '16px' : '0px'} // Add gap between cards when in vertical layout
        width="100%" // Ensure the Box takes up the full width
        mx="auto" // Center the Box horizontally
      >
        <Card
          title="World Leading Reporting"
          fadetype={ isMobile ? "fade-right" : "fade-up-right" }
          description="We aim to respond to all queries within 24 hours. and actively work to improve our service."
          style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}
          extra={{ marginRight: isMobile ? '0px' : '16px' }}
        />
        <Card
          title="Secure & Reliable"
          fadetype={ isMobile ? "fade-left" : "fade-up-left" }
          description="99.9% uptime guarantee with 24/7 email support for all your queries."
          style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}
          extra={{ marginLeft: isMobile ? '0px' : '16px' }}
        />
      </Box>

      <Typography variant='h4' marginTop="64px" sx={{ fontWeight: "700", fontSize: isMobile ? "25px" : "45px" }} data-aos="fade-up">
        What Our Customers Say
      </Typography>
      <Typography variant={isMobile ? "body1" : "h5"} marginTop="16px" style={{ color: '#FFFFFF' }} data-aos="fade-up">
        We are proud to have helped server owners from all around the world
      </Typography>
      <Grid container spacing={4} marginTop="16px">
        {[
          { name: "Leo", review: "Amazing chatting server! Highly recommend. The team was incredibly responsive and helpful throughout the entire setup. I couldn't be happier with the experience." },
          { name: "Jack", review: "Very reliable and easy to use. The interface is intuitive, and the features are exactly what I needed to manage my chats efficiently. Great job!" },
          { name: "Daniel", review: "Great support and uptime. I've never experienced any downtime, and the support team is always available to answer my questions. Highly satisfied!" },
          { name: "Tyler", review: "Fantastic features and performance. The chat history and moderation options are top-notch, and the overall performance of the server is excellent. Highly recommend!" },
          { name: "Harley", review: "User-friendly and efficient. The setup was quick and easy, and the server has been running smoothly ever since. Couldn't ask for more." },
          { name: "Danney", review: "Excellent chat history and moderation options. The peace of mind knowing my chat data is safe and can be managed at any time is invaluable. Thank you!" }
        ].slice(0, (isMobile ? 2 : 6)).map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} data-aos="fade-up">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              padding="20px"
              borderRadius="8px"
              bgcolor="#121212"
              color="#FFFFFF"
              height="100%" // Ensure the Box takes up the full height of the Grid item
            >
              <Typography variant="h6" fontWeight="700" marginBottom="10px">
                {testimonial.name}
              </Typography>
              <Box display="flex" justifyContent="center" marginBottom="10px">
                {[...Array(5)].map((_, i) => (
                  <StarIcon key={i} style={{ color: '#4F46E5' }} />
                ))}
              </Box>
              <Typography variant="body1" textAlign="center" flexGrow={1}>
                {testimonial.review}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default App;
