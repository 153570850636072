import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

const UploadProgress = ({ uploadProgress }) => {
    return (
        uploadProgress > 0 && (
            <Box sx={{ width: '100%', marginBottom: 2 }}>
                <LinearProgress variant="determinate" value={uploadProgress} />
                <Typography variant="body2" color="#ffffff" textAlign="center">
                    {uploadProgress}% uploaded
                </Typography>
            </Box>
        )
    );
};

export default UploadProgress;